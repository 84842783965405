import VueCookies from 'vue-cookies'

//Statistic
export const getData = (pageName) => {

    var data_stat = {
        "Token": VueCookies.get('cookieId'),
        "Time": new Date(Date.now()).toISOString(),
        "Page": pageName,
        // "Info": info,
    }

    return data_stat

}

export const sendCookies = (pageName) => {
    var data_stat = getData(pageName);

    fetch('/api/manual_analytics', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data_stat),
    }).then((response) => {
        response.json()
    })
}
