<template>
  <div class="lms-footer lms-footer-animation">
    <div class="lms-content-frame vertical">
      <div class="logo-row">
        <div class="social">
          <img src="@/assets/logo_white.svg">
          <div class="lms-social-links">
            <a target="_blank" href="https://www.linkedin.com/company/lumos-data-consulting/">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path id="Icon_awesome-linkedin" data-name="Icon awesome-linkedin" d="M29.714,2.25H2.279A2.3,2.3,0,0,0,0,4.557V31.943A2.3,2.3,0,0,0,2.279,34.25H29.714A2.3,2.3,0,0,0,32,31.943V4.557A2.3,2.3,0,0,0,29.714,2.25ZM9.671,29.679H4.929V14.407h4.75V29.679ZM7.3,12.321a2.75,2.75,0,1,1,2.75-2.75,2.751,2.751,0,0,1-2.75,2.75ZM27.45,29.679H22.707V22.25c0-1.771-.036-4.05-2.464-4.05-2.471,0-2.85,1.929-2.85,3.921v7.557H12.65V14.407H17.2v2.086h.064a5,5,0,0,1,4.493-2.464c4.8,0,5.693,3.164,5.693,7.279Z" transform="translate(0 -2.25)"/></svg>
            </a>
            <a target="_blank" href="https://www.facebook.com/lumosstudentdataconsulting/">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path id="Icon_ionic-logo-facebook" data-name="Icon ionic-logo-facebook" d="M34.733,4.5H6.267A1.768,1.768,0,0,0,4.5,6.267V34.733A1.768,1.768,0,0,0,6.267,36.5H20.5V23.833H16.675V19.167H20.5v-3.45c0-4.133,2.867-6.383,6.558-6.383,1.767,0,3.667.133,4.108.192v4.317H28.225c-2.008,0-2.392.95-2.392,2.35v2.975h4.783l-.625,4.667H25.833V36.5h8.9A1.768,1.768,0,0,0,36.5,34.733V6.267A1.768,1.768,0,0,0,34.733,4.5Z" transform="translate(-4.5 -4.5)"/></svg>
            </a>
            <a target="_blank" href="https://github.com/Lumos-Data">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path id="Icon_awesome-github" data-name="Icon awesome-github" d="M10.7,26.329c0,.132-.148.238-.335.238-.213.02-.361-.086-.361-.238,0-.132.148-.238.335-.238C10.535,26.071,10.7,26.177,10.7,26.329Zm-2.006-.3c-.045.132.084.285.277.324a.3.3,0,0,0,.4-.132c.039-.132-.084-.285-.277-.344A.328.328,0,0,0,8.7,26.031Zm2.852-.112c-.187.046-.316.172-.3.324.019.132.187.218.381.172s.316-.172.3-.3S11.735,25.9,11.548,25.919ZM15.794.563C6.845.563,0,7.53,0,16.708A16.565,16.565,0,0,0,10.935,32.536c.826.152,1.116-.371,1.116-.8,0-.41-.019-2.673-.019-4.063,0,0-4.516.993-5.465-1.972,0,0-.735-1.926-1.794-2.422,0,0-1.477-1.039.1-1.019a3.392,3.392,0,0,1,2.49,1.707,3.363,3.363,0,0,0,4.7,1.383A3.714,3.714,0,0,1,13.1,23.12c-3.606-.41-7.245-.946-7.245-7.312a5.081,5.081,0,0,1,1.523-3.9,6.4,6.4,0,0,1,.168-4.493C8.9,6.988,12,9.2,12,9.2a14.874,14.874,0,0,1,8.1,0s3.1-2.223,4.452-1.787a6.4,6.4,0,0,1,.168,4.493,5.2,5.2,0,0,1,1.665,3.9c0,6.385-3.8,6.895-7.406,7.312a3.967,3.967,0,0,1,1.1,3.07c0,2.23-.019,4.989-.019,5.532,0,.43.3.953,1.116.8A16.416,16.416,0,0,0,32,16.708,16.013,16.013,0,0,0,15.794.563ZM6.271,23.384c-.084.066-.065.218.045.344.1.106.252.152.335.066.084-.066.065-.218-.045-.344C6.5,23.345,6.355,23.3,6.271,23.384Zm-.7-.536c-.045.086.019.192.148.258A.189.189,0,0,0,6,23.06c.045-.086-.019-.192-.148-.258C5.723,22.762,5.619,22.782,5.574,22.848ZM7.665,25.2c-.1.086-.065.285.084.41.148.152.335.172.419.066.084-.086.045-.285-.084-.41C7.942,25.118,7.748,25.1,7.665,25.2Zm-.735-.973c-.1.066-.1.238,0,.39s.277.218.361.152a.307.307,0,0,0,0-.41C7.2,24.211,7.032,24.145,6.929,24.231Z" transform="translate(0 -0.563)"/></svg>  
            </a>
            <a target="_blank" href="https://instagram.com/lumos_data/">
              <svg class="instagram-logo" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                viewBox="0 0 551.034 551.034" style="enable-background:new 0 0 551.034 551.034;">
                  <path class="logo" id="XMLID_17_" d="M386.878,0H164.156C73.64,0,0,73.64,0,164.156v222.722 c0,90.516,73.64,164.156,164.156,164.156h222.722c90.516,0,164.156-73.64,164.156-164.156V164.156 C551.033,73.64,477.393,0,386.878,0z M495.6,386.878c0,60.045-48.677,108.722-108.722,108.722H164.156 c-60.045,0-108.722-48.677-108.722-108.722V164.156c0-60.046,48.677-108.722,108.722-108.722h222.722 c60.045,0,108.722,48.676,108.722,108.722L495.6,386.878L495.6,386.878z"/>
                
                  <path id="XMLID_81_" d="M275.517,133C196.933,133,133,196.933,133,275.516 s63.933,142.517,142.517,142.517S418.034,354.1,418.034,275.516S354.101,133,275.517,133z M275.517,362.6 c-48.095,0-87.083-38.988-87.083-87.083s38.989-87.083,87.083-87.083c48.095,0,87.083,38.988,87.083,87.083 C362.6,323.611,323.611,362.6,275.517,362.6z"/>
                
                  <circle id="XMLID_83_"  cx="418.306" cy="134.072" r="34.149"/>

              </svg>
              </a>
          </div>
        </div>
        <div class="navigation">
          <router-link
            tag="button"
            class="footer-link"
            v-for="link in footerSettings"
            :key="link.title"
            :to="link.path"
            v-scroll-to="link.scrollSettings"
            @click="showMenu = false"
            v-if="link.title !== 'Blog'"
          >
            <span v-html="link.icon"></span>
            <p>{{ link.title }}</p>
          </router-link>

          <!-- :class="{'notification': link.title == 'Apply' || link.title == 'Bewerben'}" -->
        </div>
      </div>
      <div class="legal-row">
        <router-link v-for="link in legal" :key="link.title" :to="{ name: 'Legal', params: { view: link.view }}">{{link.title}}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['footerSettings'],
  data() {
    return {
      legal: [
        {
          title: 'Impressum',
          view: 'Impressum'
        },
        {
          title: 'Privacy Policy',
          view: 'Privacy Policy'
        },
      ],
      
    }
  }
}
</script>

<style lang="scss">

  .lms-footer {
    display: flex;
    justify-content: center;
    height: 460px;
    background-color: $footer-purple;
    font-family: $avenir;

    .lms-content-frame {
      height: 100%;
    }
    
    a {
      text-decoration: none;
      transition: 0.2s ease-in-out;
    }
    
    .logo-row {
      display: flex;
      align-items: flex-start;
      margin: 60px 0 30px 0;

      .social {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0 auto 0 0;

        img {
          height: 50px;
          margin: 0 0 50px 0;
        }
      }

      .navigation {
        display: flex;
        flex-direction: column;
        text-align: right;

        .footer-link {
			    display: flex;
          align-items: center;
          font-family: $avenir;
          font-weight: 400;
          font-size: 20px;
          text-decoration: none;
          margin: 10px 0;
          border: none;
          outline: none;
          background: none;
          cursor: pointer;

          &.notification::before {
            position: absolute;
            content: "";
            width: 6px;
            height: 6px;
            border-radius: 4px;
            background: $lumos-pink;
            margin: 0 0 0 -14px;

            animation: shadowPulseSmall 1.5s ease-in-out infinite;
          }

          span {
            display: flex;
            align-items: center;
          }

          &:first-of-type {
            margin-top: 0;
          }

          svg {
            opacity: 0.6;
            transform: scale(0.8);
            transition: 0.3s;
            fill: #FFFFFF;
          }

          p {
            margin: 0 0 0 10px;
            color: #FFFFFF;
          }
        }

        .footer-link:hover svg { 
          opacity: 1;
          fill: $lumos-pink-bright;
        }
      }
    }

    .legal-row {
      margin: auto 0 50px 0;

      a {
        margin: 0 60px 0 0;
        color: white;
        opacity: 0.5;

        &:hover {
          opacity: 1;
        }
      }
    }
  }


  /* - - - - - - - - - - - - - */
  /* -  Mobile Optimization  - */
  /* - - - - - - - - - - - - - */


  @media screen and (max-width: 768px) {
    
    .lms-footer {
      display: flex;
      justify-content: center;
      height: 460px;
      background-color: $footer-purple;
      font-family: $avenir;
      padding: 0px 30px;

      .lms-content-frame {
        height: 100%;
      }
      
      a {
        text-decoration: none;
        transition: 0.2s ease-in-out;
      }

      .logo-row {
        display: flex;
        align-items: flex-start;
        margin: 60px 0 30px 0;

        .social {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin: 0 auto 0 0;

          img {
            height: 40px;
            margin: 0 0 50px 0;
          }
        }

        .navigation {
          display: flex;
          flex-direction: column;
          text-align: right;

          .footer-link {
            display: flex;
            align-items: center;
            font-family: $avenir;
            font-weight: 400;
            font-size: 20px;
            text-decoration: none;
            margin: 10px 0;
            border: none;
            outline: none;
            background: none;
            cursor: pointer;

            &:first-of-type {
              margin-top: 0;
            }

            svg {
              opacity: 0.6;
              transform: scale(0.8);
              transition: 0.3s;
              fill: #FFFFFF;
            }

            p {
              margin: 0 0 0 10px;
              color: #FFFFFF;
            }
          }

          .footer-link:hover svg { 
            opacity: 1;
            fill: $lumos-pink-bright;
          }
        }
      }

      .legal-row {
        margin: auto auto 50px auto;

        a {
          margin: 20px;
          color: white;
          opacity: 0.5;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
</style>