<template>
	<div id="legal">
		<div class="lms-navbar" :class="{ 'open': showMenu }">
			<div class="lms-content-frame">
				<div class="logo-container">
					<router-link to="/">
						<img src="../assets/logo_regular.svg" alt='lumos logo'>
					</router-link>
					<button @click="showMenu = !showMenu" class="nav-link menu mobile">
						<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
							<path
								d="M11,0A11,11,0,1,1,0,11,11,11,0,0,1,11,0Zm0,2a9,9,0,1,0,9,9A9,9,0,0,0,11,2Zm3.924,3.811a1,1,0,0,1,1.265,1.265h0l-2.12,6.36a1,1,0,0,1-.632.632h0l-6.36,2.12a1,1,0,0,1-1.265-1.265h0l2.12-6.36a1,1,0,0,1,.632-.632h0Zm-1.266,2.53L9.67,9.67,8.341,13.659l3.988-1.33Z" />
						</svg>
					</button>
				</div>
				<div class="button-container">
					<button @click="view = 'Impressum'; showMenu = false" class="nav-link">Impressum</button>
					<button @click="view = 'Privacy Policy'; showMenu = false" class="nav-link">Privacy Policy</button>
					<router-link to="/" class="lms-main-button" tag="button">Home</router-link>
				</div>
			</div>
		</div>

		<div class="lms-content-frame">
			<div class="impressum" v-if="view === 'Impressum'">
				<div class="content">
					<h1>{{ view }}</h1>
					<p>
						Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung
						und
						Offenlegungspflicht laut §25 Mediengesetz.
					</p>
					<h3>Vereinsname</h3>
					<p>Lumos Student Data Consulting - Verein von Studierenden die sich im Bereich Data Science
						betätigen</p>
					<h3>ZVR</h3>
					<p>1838703184</p>
					<h3>Vereinssitz</h3>
					<p>Klosterneuburger Straße 40/13<br>1200 Wien,<br>Österreich<br>E-Mail: office@lumos-consulting.at
					</p>
					<h3>Vereinszweck</h3>
					<p>
						Lumos gibt engagierten und talentierten Data Science Studenten die Möglichkeit, sich
						weiterzubilden und an
						professionellen Aufgabenstellungen zu arbeiten. Wir sind ein junges dynamisches Team mit
						Arbeitserfahrung in
						Software Engineering, FinTech, Data Analytics, Business Intelligence und bieten individuelle,
						state-of-the-art
						Lösungen im Bereich Data Science an.
					</p>
					<br>
					<br>
					<h2>EU-Streitschlichtung</h2>
					<p>
						Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung)
						möchten wir Sie über
						die Online-Streitbeilegungsplattform (OS-Plattform) informieren.
					</p>
					<p>
						Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der
						Europäischen Kommission
						unter
						<a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE">
							http://ec.europa.eu/odr?tid=221123156
						</a>
						zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.
					</p>
					<p>
						Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an
						Streitbeilegungsverfahren
						vor einer Verbraucherschlichtungsstelle teilzunehmen.
					</p>
					<br>
					<h2>Haftung für Inhalte dieser Website</h2>
					<p>
						Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns korrekte und aktuelle
						Informationen
						bereitzustellen. Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser
						Webseite übernehmen,
						speziell für jene die seitens Dritter bereitgestellt wurden.
					</p>
					<p>
						Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitten wir Sie uns umgehend
						zu kontaktieren, Sie
						finden die Kontaktdaten im Impressum.
					</p>
					<br>
					<h2>Haftung für Links auf dieser Website</h2>
					<p>
						Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht verantwortlich
						sind. Haftung für
						verlinkte Websites besteht laut
						<a
							href="https://www.ris.bka.gv.at/Dokument.wxe?Abfrage=Bundesnormen&amp;Dokumentnummer=NOR40025813&amp;tid=221123156">
							§ 17 ECG</a> für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und
						haben, uns solche
						Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort entfernen würden,
						wenn uns
						Rechtswidrigkeiten bekannt werden.
					</p>
					<p>
						Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitten wir Sie uns zu
						kontaktieren, Sie finden die
						Kontaktdaten im Impressum.
					</p>
					<p>
						Quelle: Erstellt mit dem Datenschutz Generator von <a
							href="https://www.adsimple.at/online-marketing/">AdSimple®
							Online Marketing</a> in Kooperation mit <a
							href="https://www.meinhaushalt.at/">meinhaushalt.at</a>
					</p>
				</div>
			</div>

			<div class="privacy" v-if="view === 'Privacy Policy'">
				<div class="content">
					<h1>{{ view }}</h1>
					<h2>Datenschutz</h2>
					<p>Wir haben diese Datenschutzerklärung (Fassung 18.04.2020-111267119) verfasst, um Ihnen gemäß der
						Vorgaben der <a
							href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=111267119">Datenschutz-Grundverordnung
							(EU) 2016/679</a> zu erklären, welche Informationen wir sammeln, wie wir Daten verwenden und
						welche
						Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben.</p>
					<p>Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr technisch klingen, wir haben
						uns bei der
						Erstellung jedoch bemüht die wichtigsten Dinge so einfach und klar wie möglich zu beschreiben.
					</p>

					<h2>Automatische Datenspeicherung</h2>
					<p>Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen automatisch erstellt und
						gespeichert, so auch
						auf dieser Webseite.</p>
					<p>Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser Webserver (Computer auf
						dem diese Webseite
						gespeichert ist) automatisch Daten in Datein (Webserver-Logfiles) wie</p>
					<ul class="list">
						<li>die Adresse (URL) der aufgerufenen Webseite</li>
						<li>Browser und Browserversion</li>
						<li>das verwendete Betriebssystem</li>
						<li>die Adresse (URL) der zuvor besuchten Seite (Referrer URL)</li>
						<li>den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird</li>
						<li>Datum und Uhrzeit</li>
					</ul>
					<p>In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und danach automatisch gelöscht.
						Wir geben diese
						Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von
						rechtswidrigem Verhalten
						eingesehen werden.</p>

					<h2>Cookies</h2>
					<p>Unsere Webseite verwendet HTTP-Cookies, um nutzerspezifische Daten zu speichern.</p>
					<p>Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende
						Datenschutzerklärung besser verstehen.</p>

					<h2>Was genau sind Cookies</h2>
					<p>Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind
						beispielsweise Chrome,
						Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Webseiten speichern kleine
						Text-Dateien in Ihrem
						Browser. Diese Dateien nennt man Cookies.</p>
					<p>Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle
						Webseiten verwenden
						Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies für andere
						Anwendungsbereiche gibt.
						HTTP-Cookies sind kleine Dateien, die von unserer Webseite auf Ihrem Computer gespeichert
						werden. Diese
						Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem “Hirn” Ihres Browsers,
						untergebracht. Ein Cookie
						besteht aus einem Namen und einem Wert. Bei der Definition eines Cookies müssen zusätzlich ein
						oder mehrere
						Attribute angegeben werden.</p>
					<p>Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche
						Seiteneinstellungen.
						Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“ Informationen
						an unsere Seite
						zurück. Dank der Cookies weiß unsere Webseite, wer Sie sind und bietet Ihnen die Einstellung,
						die Sie gewohnt sind.
						In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie beispielsweise Firefox
						sind alle Cookies in
						einer einzigen Datei gespeichert.</p>
					<p>Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden
						direkt von unserer
						Seite erstellt, Drittanbieter-Cookies werden von Partner-Webseiten (z.B. Google Analytics)
						erstellt. Jedes Cookie
						ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines
						Cookies variiert von
						ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme und enthalten
						keine Viren,
						Trojaner oder andere „Schädlinge“. Cookies können auch nicht auf Informationen Ihres PCs
						zugreifen.</p>
					<p>So können zum Beispiel Cookie-Daten aussehen:</p>

					<h2>Welche Arten von Cookies gibt es?</h2>
					<p>Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und
						wird in den
						folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf
						die verschiedenen
						Arten von HTTP-Cookies eingehen.</p>
					<p>Man kann 4 Arten von Cookies unterscheiden:</p>
					<h3>Unerlässliche Cookies</h3>
					<p>Diese Cookies sind nötig, um grundlegende Funktionen der Webseite sicherzustellen. Zum Beispiel
						braucht es diese
						Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft
						und später erst zur
						Kasse geht. Durch diese Cookies wird der Warenkorb nicht gelöscht, selbst wenn der User sein
						Browserfenster
						schließt.</p>
					<h3>Zweckmäßige Cookies</h3>
					<p>Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige Fehlermeldungen
						bekommt. Zudem werden
						mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Webseite bei verschiedenen
						Browsern gemessen.</p>
					<h3>Zielorientierte Cookies</h3>
					<p>Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene
						Standorte,
						Schriftgrößen oder Formulardaten gespeichert.</p>
					<h3>Werbe Cookies</h3>
					<p>Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell
						angepasste Werbung zu
						liefern. Das kann sehr praktisch, aber auch sehr nervig sein.</p>
					<p>Üblicherweise werden Sie beim erstmaligen Besuch einer Webseite gefragt, welche dieser
						Cookiearten Sie zulassen
						möchten. Und natürlich wird diese Entscheidung auch in einem Cookie gespeichert.</p>

					<h2>Wie kann ich Cookies löschen?</h2>
					<p>Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service
						oder welcher
						Webseite die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, zu
						deaktivieren oder nur teilweise
						zuzulassen. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle anderen
						Cookies zulassen.</p>
					<p>Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie
						Cookie-Einstellungen
						ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen finden:</p>
					<ul class="list">
						<li><a href="https://support.google.com/chrome/answer/95647?tid=111267119">Chrome: Cookies in
								Chrome löschen,
								aktivieren und verwalten</a></li>
						<li><a href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111267119">Safari:
								Verwalten von Cookies
								und Websitedaten mit Safari</a></li>
						<li><a
								href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111267119">Firefox:
								Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt
								haben</a></li>
						<li><a
								href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111267119">Internet
								Explorer: Löschen und Verwalten von Cookies</a></li>
						<li><a
								href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111267119">Microsoft
								Edge:
								Löschen und Verwalten von Cookies</a></li>
					</ul>
					<p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass
						er Sie immer
						informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
						entscheiden, ob Sie das
						Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser verschieden. Am besten Sie
						suchen die Anleitung in
						Google mit dem Suchbegriff “Cookies löschen Chrome” oder “Cookies deaktivieren Chrome” im Falle
						eines Chrome
						Browsers.</p>

					<h2>Wie sieht es mit meinem Datenschutz aus?</h2>
					<p>Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das
						Speichern von Cookies eine
						Einwilligung des von Ihnen verlangt. Innerhalb der EU-Länder gibt es allerdings noch sehr
						unterschiedliche
						Reaktionen auf diese Richtlinien. In Österreich erfolgte aber die Umsetzung dieser Richtlinie in
						§ 96 Abs. 3 des
						Telekommunikationsgesetzes (TKG).</p>
					<p>Wenn Sie mehr über Cookies wissen möchten und vor technischen Dokumentationen nicht
						zurückscheuen, empfehlen wir <a
							href="https://tools.ietf.org/html/rfc6265">https://tools.ietf.org/html/rfc6265</a>, dem
						Request for Comments der
						Internet Engineering Task Force (IETF) namens “HTTP State Management Mechanism”.</p>

					<h2>Speicherung persönlicher Daten</h2>
					<p>Persönliche Daten, die Sie uns auf dieser Website elektronisch übermitteln, wie zum Beispiel
						Name, E-Mail-Adresse,
						Adresse oder andere persönlichen Angaben im Rahmen der Übermittlung eines Formulars oder
						Kommentaren im Blog, werden
						von uns gemeinsam mit dem Zeitpunkt und der IP-Adresse nur zum jeweils angegebenen Zweck
						verwendet, sicher verwahrt
						und nicht an Dritte weitergegeben.</p>
					<p>Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit jenen Besuchern, die
						Kontakt ausdrücklich
						wünschen und für die Abwicklung der auf dieser Webseite angebotenen Dienstleistungen und
						Produkte. Wir geben Ihre
						persönlichen Daten ohne Zustimmung nicht weiter, können jedoch nicht ausschließen, dass diese
						Daten beim Vorliegen
						von rechtswidrigem Verhalten eingesehen werden.</p>
					<p>Wenn Sie uns persönliche Daten per E-Mail schicken – somit abseits dieser Webseite – können wir
						keine sichere
						Übertragung und den Schutz Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten
						niemals unverschlüsselt
						per E-Mail zu übermitteln.</p>

					<h2>Rechte laut Datenschutzverordnung</h2>
					<p>Ihnen stehen laut den Bestimmungen der DSGVO und des österreichischen <a
							href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=10001597&tid=111267119">Datenschutzgesetzes
							(DSG)</a> grundsätzlich die folgende Rechte zu:</p>
					<ul class="list">
						<li>Recht auf Berichtigung (Artikel 16 DSGVO)</li>
						<li>Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)</li>
						<li>Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)</li>
						<li>Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit der Berichtigung oder
							Löschung
							personenbezogener Daten oder der Einschränkung der Verarbeitung (Artikel 19 DSGVO)
						</li>
						<li>Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)</li>
						<li>Widerspruchsrecht (Artikel 21 DSGVO)</li>
						<li>Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung — einschließlich
							Profiling — beruhenden
							Entscheidung unterworfen zu werden (Artikel 22 DSGVO)
						</li>
					</ul>
					<p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
						datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei
						der Aufsichtsbehörde
						beschweren, welche in Österreich die Datenschutzbehörde ist, deren Webseite Sie unter <a
							href="https://www.dsb.gv.at/?tid=111267119"> https://www.dsb.gv.at/</a> finden.</p>

					<h2>Auswertung des Besucherverhalten</h2>
					<p>In der folgenden Datenschutzerklärung informieren wir Sie darüber, ob und wie wir Daten Ihres
						Besuchs dieser
						Website auswerten. Die Auswertung der gesammelten Daten erfolgt in der Regel anonym und wir
						können von Ihrem
						Verhalten auf dieser Website nicht auf Ihre Person schließen.</p>
					<p>Mehr über Möglichkeiten dieser Auswertung der Besuchsdaten zu widersprechen erfahren Sie in der
						folgenden
						Datenschutzerklärung.</p>

					<h2>TLS-Verschlüsselung mit https</h2>
					<p>Wir verwenden https um Daten abhörsicher im Internet zu übertragen (Datenschutz durch
						Technikgestaltung <a
							href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE&tid=111267119">Artikel
							25
							Absatz 1 DSGVO)</a>. Durch den Einsatz von TLS (Transport Layer Security), einem
						Verschlüsselungsprotokoll zur
						sicheren Datenübertragung im Internet können wir den Schutz vertraulicher Daten sicherstellen.
						Sie erkennen die
						Benutzung dieser Absicherung der Datenübertragung am kleinen Schlosssymbol links oben im Browser
						und der Verwendung
						des Schemas https (anstatt http) als Teil unserer Internetadresse.</p>

					<h2>Google Fonts Datenschutzerklärung</h2>
					<p>Auf unserer Website verwenden wir Google Fonts. Das sind die “Google-Schriften” der Firma Google
						Inc. Für den
						europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin
						4, Irland) für alle
						Google-Dienste verantwortlich.</p>
					<p>Für die Verwendung von Google-Schriftarten müssen Sie sich nicht anmelden bzw. ein Passwort
						hinterlegen. Weiters
						werden auch keine Cookies in Ihrem Browser gespeichert. Die Dateien (CSS, Schriftarten/Fonts)
						werden über die
						Google-Domains fonts.googleapis.com und fonts.gstatic.com angefordert. Laut Google sind die
						Anfragen nach CSS und
						Schriften vollkommen getrennt von allen anderen Google-Diensten. Wenn Sie ein Google-Konto
						haben, brauchen Sie keine
						Sorge haben, dass Ihre Google-Kontodaten, während der Verwendung von Google Fonts, an Google
						übermittelt werden.
						Google erfasst die Nutzung von CSS (Cascading Style Sheets) und der verwendeten Schriftarten und
						speichert diese
						Daten sicher. Wie die Datenspeicherung genau aussieht, werden wir uns noch im Detail ansehen.
					</p>

					<h2>Was sind Google Fonts?</h2>
					<p>Google Fonts (früher Google Web Fonts) ist ein Verzeichnis mit über 800 Schriftarten, die Google
						Ihren Nutzern
						kostenlos zu Verfügung stellen.</p>
					<p>Viele dieser Schriftarten sind unter der SIL Open Font License veröffentlicht, während andere
						unter der
						Apache-Lizenz veröffentlicht wurden. Beides sind freie Software-Lizenzen.</p>

					<h2>Warum verwenden wir Google Fonts?</h2>
					<p>Mit Google Fonts können wir auf der eigenen Webseite Schriften nutzen, und müssen sie nicht auf
						unserem eigenen
						Server hochladen. Google Fonts ist ein wichtiger Baustein, um die Qualität unserer Webseite hoch
						zu halten. Alle
						Google-Schriften sind automatisch für das Web optimiert und dies spart Datenvolumen und ist
						speziell für die
						Verwendung bei mobilen Endgeräten ein großer Vorteil. Wenn Sie unsere Seite besuchen, sorgt die
						niedrige Dateigröße
						für eine schnelle Ladezeit. Des Weiteren sind Google Fonts sichere Web Fonts. Unterschiedliche
						Bildsynthese-Systeme
						(Rendering) in verschiedenen Browsern, Betriebssystemen und mobilen Endgeräten können zu Fehlern
						führen. Solche
						Fehler können teilweise Texte bzw. ganze Webseiten optisch verzerren. Dank des schnellen Content
						Delivery Network
						(CDN) gibt es mit Google Fonts keine plattformübergreifenden Probleme. Google Fonts unterstützt
						alle gängigen
						Browser (Google Chrome, Mozilla Firefox, Apple Safari, Opera) und funktioniert zuverlässig auf
						den meisten modernen
						mobilen Betriebssystemen, einschließlich Android 2.2+ und iOS 4.2+ (iPhone, iPad, iPod). Wir
						verwenden die Google
						Fonts also, damit wir unser gesamtes Online-Service so schön und einheitlich wie möglich
						darstellen können.</p>

					<h2>Welche Daten werden von Google gespeichert?</h2>
					<p>Wenn Sie unsere Webseite besuchen, werden die Schriften über einen Google-Server nachgeladen.
						Durch diesen externen
						Aufruf werden Daten an die Google-Server übermittelt. So erkennt Google auch, dass Sie bzw. Ihre
						IP-Adresse unsere
						Webseite besucht. Die Google Fonts API wurde entwickelt, um Verwendung, Speicherung und
						Erfassung von Endnutzerdaten
						auf das zu reduzieren, was für eine ordentliche Bereitstellung von Schriften nötig ist. API
						steht übrigens für
						„Application Programming Interface“ und dient unter anderem als Datenübermittler im
						Softwarebereich.</p>
					<p>Google Fonts speichert CSS- und Schrift-Anfragen sicher bei Google und ist somit geschützt. Durch
						die gesammelten
						Nutzungszahlen kann Google feststellen, wie gut die einzelnen Schriften ankommen. Die Ergebnisse
						veröffentlicht
						Google auf internen Analyseseiten, wie beispielsweise Google Analytics. Zudem verwendet Google
						auch Daten des
						eigenen Web-Crawlers, um festzustellen, welche Webseiten Google-Schriften verwenden. Diese Daten
						werden in der
						BigQuery-Datenbank von Google Fonts veröffentlicht. Unternehmer und Entwickler nützen das
						Google-Webservice
						BigQuery, um große Datenmengen untersuchen und bewegen zu können.</p>
					<p>Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage auch Informationen wie
						Spracheinstellungen,
						IP-Adresse, Version des Browsers, Bildschirmauflösung des Browsers und Name des Browsers
						automatisch an die
						Google-Server übertragen werden. Ob diese Daten auch gespeichert werden, ist nicht klar
						feststellbar bzw. wird von
						Google nicht eindeutig kommuniziert.</p>

					<h2>Wie lange und wo werden die Daten gespeichert?</h2>
					<p>Anfragen für CSS-Assets speichert Google einen Tag lang auf seinen Servern, die hauptsächlich
						außerhalb der EU
						angesiedelt sind. Das ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten zu
						nutzen. Ein Stylesheet
						ist eine Formatvorlage, über die man einfach und schnell z.B. das Design bzw. die Schriftart
						einer Webseite ändern
						kann.</p>
					<p>Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt damit das Ziel, die
						Ladezeit von Webseiten
						grundsätzlich zu verbessern. Wenn Millionen von Webseiten auf die gleichen Schriften verweisen,
						werden sie nach dem
						ersten Besuch zwischengespeichert und erscheinen sofort auf allen anderen später besuchten
						Webseiten wieder.
						Manchmal aktualisiert Google Schriftdateien, um die Dateigröße zu reduzieren, die Abdeckung von
						Sprache zu erhöhen
						und das Design zu verbessern.</p>

					<h2>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h2>
					<p>Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können nicht einfach gelöscht
						werden. Die Daten werden
						beim Seitenaufruf automatisch an Google übermittelt. Um diese Daten vorzeitig löschen zu können,
						müssen Sie den
						Google-Support auf <a
							href="https://support.google.com/?hl=de&tid=111267119">https://support.google.com/?hl=de&tid=111267119</a>
						kontaktieren. Datenspeicherung verhindern Sie in diesem Fall nur, wenn Sie unsere Seite nicht
						besuchen.</p>
					<p>Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle
						Schriftarten. Wir können also
						unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite
						rausholen. Mehr zu Google
						Fonts und weiteren Fragen finden Sie auf <a
							href="https://developers.google.com/fonts/faq?tid=111267119">https://developers.google.com/fonts/faq?tid=111267119.</a>
						Dort geht zwar Google auf datenschutzrelevante Angelegenheiten ein, doch wirklich detaillierte
						Informationen über
						Datenspeicherung sind nicht enthalten. Es ist relativ schwierig, von Google wirklich präzise
						Informationen über
						gespeicherten Daten zu bekommen.</p>
					<p>Welche Daten grundsätzlich von Google erfasst werden und wofür diese Daten verwendet werden,
						können Sie auch auf <a
							href="https://policies.google.com/privacy?hl=de&tid=111267119">https://www.google.com/intl/de/policies/privacy/</a>
						nachlesen.</p>

					<h2>Google Analytics Datenschutzerklärung</h2>
					<p>Wir verwenden auf unserer Website das Analyse-Tracking Tool Google Analytics (GA) des
						amerikanischen Unternehmens
						Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House,
						Barrow Street Dublin
						4, Irland) für alle Google-Dienste verantwortlich. Google Analytics sammelt Daten über Ihre
						Handlungen auf unserer
						Website. Wenn Sie beispielsweise einen Link anklicken, wird diese Aktion in einem Cookie
						gespeichert und an Google
						Analytics versandt. Mithilfe der Berichte, die wir von Google Analytics erhalten, können wir
						unsere Website und
						unser Service besser an Ihre Wünsche anpassen. Im Folgenden gehen wir näher auf das Tracking
						Tool ein und
						informieren Sie vor allem darüber, welche Daten gespeichert werden und wie Sie das verhindern
						können.</p>

					<h2>Was ist Google Analytics?</h2>
					<p>Google Analytics ist ein Trackingtool, das der Datenverkehrsanalyse unserer Website dient. Damit
						Google Analytics
						funktioniert, wird ein Tracking-Code in den Code unserer Website eingebaut. Wenn Sie unsere
						Website besuchen,
						zeichnet dieser Code verschiedene Handlungen auf, die Sie auf unserer Website ausführen. Sobald
						Sie unsere Website
						verlassen, werden diese Daten an die Google-Analytics-Server gesendet und dort gespeichert.</p>
					<p>Google verarbeitet die Daten und wir bekommen Berichte über Ihr Userverhalten. Dabei kann es sich
						unter anderem um
						folgende Berichte handeln:</p>
					<ul class="list">
						<li>Zielgruppenberichte: Über Zielgruppenberichte lernen wir unsere User besser kennen und
							wissen genauer, wer sich
							für unser Service interessiert.
						</li>
						<li>Anzeigeberichte: Durch Anzeigeberichte können wir unsere Onlinewerbung leichter analysieren
							und verbessern.</li>
						<li>Akquisitionsberichte: Akquisitionsberichte geben uns hilfreiche Informationen darüber, wie
							wir mehr Menschen für
							unser Service begeistern können.
						</li>
						<li>Verhaltensberichte: Hier erfahren wir, wie Sie mit unserer Website interagieren. Wir können
							nachvollziehen
							welchen Weg Sie auf unserer Seite zurücklegen und welche Links Sie anklicken.
						</li>
						<li>Conversionsberichte: Conversion nennt man einen Vorgang, bei dem Sie aufgrund einer
							Marketing-Botschaft eine
							gewünschte Handlung ausführen. Zum Beispiel, wenn Sie von einem reinen Websitebesucher zu
							einem Käufer oder
							Newsletter-Abonnent werden. Mithilfe dieser Berichte erfahren wir mehr darüber, wie unsere
							Marketing-Maßnahmen bei
							Ihnen ankommen. So wollen wir unsere Conversionrate steigern.
						</li>
						<li>Echtzeitberichte: Hier erfahren wir immer sofort, was gerade auf unserer Website passiert.
							Zum Beispiel sehen
							wir wie viele User gerade diesen Text lesen.
						</li>
					</ul>

					<h2>Warum verwenden wir Google Analytics auf unserer Webseite?</h2>
					<p>Unser Ziel mit dieser Website ist klar: Wir wollen Ihnen das bestmögliche Service bieten. Die
						Statistiken und Daten
						von Google Analytics helfen uns dieses Ziel zu erreichen.</p>
					<p>Die statistisch ausgewerteten Daten zeigen uns ein klares Bild von den Stärken und Schwächen
						unserer Website.
						Einerseits können wir unsere Seite so optimieren, dass sie von interessierten Menschen auf
						Google leichter gefunden
						wird. Andererseits helfen uns die Daten, Sie als Besucher besser zu verstehen. Wir wissen somit
						sehr genau, was wir
						an unserer Website verbessern müssen, um Ihnen das bestmögliche Service zu bieten. Die Daten
						dienen uns auch, unsere
						Werbe- und Marketing-Maßnahmen individueller und kostengünstiger durchzuführen. Schließlich
						macht es nur Sinn,
						unsere Produkte und Dienstleistungen Menschen zu zeigen, die sich dafür interessieren.</p>

					<h2>Welche Daten werden von Google Analytics gespeichert?</h2>
					<p>Google Analytics erstellt mithilfe eines Tracking-Codes eine zufällige, eindeutige ID, die mit
						Ihrem Browser-Cookie
						verbunden ist. So erkennt Sie Google Analytics als neuen User. Wenn Sie das nächste Mal unsere
						Seite besuchen,
						werden Sie als „wiederkehrender“ User erkannt. Alle gesammelten Daten werden gemeinsam mit
						dieser User-ID
						gespeichert. So ist es überhaupt erst möglich pseudonyme Userprofile auszuwerten.</p>
					<p>Durch Kennzeichnungen wie Cookies und App-Instanz-IDs werden Ihre Interaktionen auf unserer
						Website gemessen.
						Interaktionen sind alle Arten von Handlungen, die Sie auf unserer Website ausführen. Wenn Sie
						auch andere
						Google-Systeme (wie z.B. ein Google-Konto) nützen, können über Google Analytics generierte Daten
						mit
						Drittanbieter-Cookies verknüpft werden. Google gibt keine Google Analytics-Daten weiter, außer
						wir als
						Websitebetreiber genehmigen das. Zu Ausnahmen kann es kommen, wenn es gesetzlich erforderlich
						ist.</p>
					<h3>Folgende Cookies werden von Google Analytics verwendet:</h3><br>
					<p>Name: _ga<br>Wert: 2.1326744211.152111268183-5<br>Verwendungszweck: Standardmäßig verwendet
						analytics.js das Cookie
						_ga, um die User-ID zu speichern. Grundsätzlich dient es zur Unterscheidung der
						Webseitenbesucher.<br>Ablaufdatum:
						nach 2 Jahren</p>
					<p>Name: _gid<br>Wert:2.1687193234.152111268183-1<br>Verwendungszweck: Das Cookie dient auch zur
						Unterscheidung der
						Webseitenbesucher<br>Ablaufdatum: nach 24 Stunden</p>
					<p>Name: _gat_gtag_UA_&lt;property-id&gt;<br>Wert: 1<br>Verwendungszweck: Wird zum Senken der
						Anforderungsrate
						verwendet. Wenn Google Analytics über den Google Tag Manager bereitgestellt wird, erhält dieser
						Cookie den Namen
						_dc_gtm_ &lt;property-id&gt;.<br>Ablaufdatum: nach 1 Minute</p>
					<p>Name: AMP_TOKEN<br>Wert: keine Angaben<br>Verwendungszweck: Das Cookie hat einen Token, mit dem
						eine User ID vom
						AMP-Client-ID-Dienst abgerufen werden kann. Andere mögliche Werte weisen auf eine Abmeldung,
						eine Anfrage oder einen
						Fehler hin.<br>Ablaufdatum: nach 30 Sekunden bis zu einem Jahr</p>
					<p>Name: __utma<br>Wert:1564498958.1564498958.1564498958.1<br>Verwendungszweck: Mit diesem Cookie
						kann man Ihr
						Verhalten auf der Website verfolgen und die Leistung messen. Das Cookie wird jedes Mal
						aktualisiert, wenn
						Informationen an Google Analytics gesendet werden.<br>Ablaufdatum: nach 2 Jahren</p>
					<p>Name: __utmt<br>Wert: 1<br>Verwendungszweck: Das Cookie wird wie _gat_gtag_UA_&lt;property-id&gt;
						zum Drosseln der
						Anforderungsrate verwendet.<br>Ablaufdatum: nach 10 Minuten</p>
					<p>Name: __utmb<br>Wert:3.10.1564498958<br>Verwendungszweck: Dieses Cookie wird verwendet, um neue
						Sitzungen zu
						bestimmen. Es wird jedes Mal aktualisiert, wenn neue Daten bzw. Infos an Google Analytics
						gesendet werden.<br>Ablaufdatum:
						nach 30 Minuten</p>
					<p>Name: __utmc<br>Wert: 167421564<br>Verwendungszweck: Dieses Cookie wird verwendet, um neue
						Sitzungen für
						wiederkehrende Besucher festzulegen. Dabei handelt es sich um ein Session-Cookie und wird nur
						solange gespeichert,
						bis Sie den Browser wieder schließen.<br>Ablaufdatum: Nach Schließung des Browsers</p>
					<p>Name: __utmz<br>Wert: m|utmccn=(referral)|utmcmd=referral|utmcct=/<br>Verwendungszweck: Das
						Cookie wird verwendet,
						um die Quelle des Besucheraufkommens auf unserer Website zu identifizieren. Das heißt, das
						Cookie speichert, von wo
						Sie auf unsere Website gekommen sind. Das kann eine andere Seite bzw. eine Werbeschaltung
						gewesen sein.<br>Ablaufdatum:
						nach 6 Monaten</p>
					<p>Name: __utmv<br>Wert: keine Angabe<br>Verwendungszweck: Das Cookie wird verwendet, um
						benutzerdefinierte Userdaten
						zu speichern. Es wird immer aktualisiert, wenn Informationen an Google Analytics gesendet
						werden.<br>Ablaufdatum:
						nach 2 Jahren</p>
					<h3>Anmerkung:</h3>
					<p>Diese Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google die Wahl ihrer
						Cookies immer wieder
						auch verändert.</p>
					<p>Hier zeigen wir Ihnen einen Überblick über die wichtigsten Daten, die mit Google Analytics
						erhoben werden:</p>
					<p><strong>Heatmaps: </strong>Google legt sogenannte Heatmaps an. Über Heatmaps sieht man genau jene
						Bereiche, die Sie
						anklicken. So bekommen wir Informationen, wo Sie auf unserer Seite „unterwegs“ sind.</p>
					<p><strong>Sitzungsdauer: </strong>Als Sitzungsdauer bezeichnet Google die Zeit, die Sie auf unserer
						Seite verbringen,
						ohne die Seite zu verlassen. Wenn Sie 20 Minuten inaktiv waren, endet die Sitzung automatisch.
					</p>
					<p><strong>Absprungrate: </strong>(engl. Bouncerate): Von einem Absprung ist die Rede, wenn Sie auf
						unserer Website
						nur eine Seite ansehen und dann unsere Website wieder verlassen.</p>
					<p><strong>Kontoerstellung: </strong>Wenn Sie auf unserer Website ein Konto erstellen bzw. eine
						Bestellung machen,
						erhebt Google Analytics diese Daten.</p>
					<p><strong>IP-Adresse: </strong>Die IP-Adresse wird nur in gekürzter Form dargestellt, damit keine
						eindeutige
						Zuordnung möglich ist.</p>
					<p><strong>Standort: </strong>Über die IP-Adresse kann das Land und Ihr ungefährer Standort bestimmt
						werden. Diesen
						Vorgang bezeichnet man auch als IP- Standortbestimmung.</p>
					<p><strong>Technische Informationen: </strong>Zu den technischen Informationen zählen unter anderem
						Ihr Browsertyp,
						Ihr Internetanbieter oder Ihre Bildschirmauflösung.</p>
					<p><strong>Herkunftsquelle: </strong>Google Analytics beziehungsweise uns interessiert natürlich
						auch über welche
						Website oder welche Werbung Sie auf unsere Seite gekommen sind.</p>
					<p>Weitere Daten sind Kontaktdaten, etwaige Bewertungen, das Abspielen von Medien (z.B., wenn Sie
						ein Video über
						unsere Seite abspielen), das Teilen von Inhalten über Social Media oder das Hinzufügen zu Ihren
						Favoriten. Die
						Aufzählung hat keinen Vollständigkeitsanspruch und dient nur zu einer allgemeinen Orientierung
						der Datenspeicherung
						durch Google Analytics.</p>

					<h2>Wie lange und wo werden die Daten gespeichert?</h2>
					<p>Google hat Ihre Server auf der ganzen Welt verteilt. Die meisten Server befinden sich in Amerika
						und folglich
						werden Ihre Daten meist auf amerikanischen Servern gespeichert. Hier können Sie genau nachlesen
						wo sich die
						Google-Rechenzentren befinden: <a
							href="https://www.google.com/about/datacenters/inside/locations/?hl=de">https://www.google.com/about/datacenters/inside/locations/?hl=de</a>
					</p>
					<p>Ihre Daten werden auf verschiedenen physischen Datenträgern verteilt. Das hat den Vorteil, dass
						die Daten schneller
						abrufbar sind und vor Manipulation besser geschützt sind. In jedem Google-Rechenzentrum gibt es
						entsprechende
						Notfallprogramme für Ihre Daten. Wenn beispielsweise die Hardware bei Google ausfällt oder
						Naturkatastrophen Server
						lahmlegen, bleibt das Risiko einer Dienstunterbrechung bei Google dennoch gering. Standardisiert
						ist bei Google
						Analytics eine Aufbewahrungsdauer Ihrer Userdaten von 26 Monaten eingestellt. Dann werden Ihre
						Userdaten gelöscht.
						Allerdings haben wir die Möglichkeit, die Aufbewahrungsdauer von Nutzdaten selbst zu wählen.
						Dafür stehen uns fünf
						Varianten zur Verfügung:</p>
					<ul class="list">
						<li>Löschung nach 14 Monaten</li>
						<li>Löschung nach 26 Monaten</li>
						<li>Löschung nach 38 Monaten</li>
						<li>Löschung nach 50 Monaten</li>
						<li>Keine automatische Löschung</li>
					</ul>
					<p>Wenn der festgelegte Zeitraum abgelaufen ist, werden einmal im Monat die Daten gelöscht. Diese
						Aufbewahrungsdauer
						gilt für Ihre Daten, die mit Cookies, Usererkennung und Werbe-IDs (z.B. Cookies der
						DoubleClick-Domain) verknüpft
						sind. Berichtergebnisse basieren auf aggregierten Daten und werden unabhängig von Nutzerdaten
						gespeichert.
						Aggregierte Daten sind eine Zusammenschmelzung von Einzeldaten zu einer größeren Einheit.</p>

					<h2>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h2>
					<p>Nach dem Datenschutzrecht der Europäischen Union haben Sie das Recht, Auskunft über Ihre Daten zu
						erhalten, sie zu
						aktualisieren, zu löschen oder einzuschränken. Mithilfe des Browser-Add-ons zur Deaktivierung
						von Google
						Analytics-JavaScript (ga.js, analytics.js, dc.js) verhindern Sie, dass Google Analytics Ihre
						Daten verwendet. Das
						Browser-Add-on können Sie unter <a
							href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de</a>
						runterladen und installieren. Beachten Sie bitte, dass durch dieses Add-on nur die Datenerhebung
						durch Google
						Analytics deaktiviert wird.</p>
					<p>Falls Sie grundsätzlich Cookies (unabhängig von Google Analytics) deaktivieren, löschen oder
						verwalten wollen, gibt
						es für jeden Browser eine eigene Anleitung:</p>
					<ul class="list">
						<li><a href="https://support.google.com/chrome/answer/95647?tid=111268183">Chrome: Cookies in
								Chrome löschen,
								aktivieren und verwalten</a></li>
						<li><a href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111268183">Safari:
								Verwalten von Cookies
								und Websitedaten mit Safari</a></li>
						<li><a
								href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111268183">Firefox:
								Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt
								haben</a></li>
						<li><a
								href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111268183">Internet
								Explorer: Löschen und Verwalten von Cookies</a></li>
						<li><a
								href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111268183">Microsoft
								Edge:
								Löschen und Verwalten von Cookies</a></li>
					</ul>
					<p>Google Analytics ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework, wodurch der
						korrekte und sichere
						Datentransfer persönlicher Daten geregelt wird. Mehr Informationen dazu finden Sie auf <a
							href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&tid=111268183.</a>
						Wir hoffen wir konnten Ihnen die wichtigsten Informationen rund um die Datenverarbeitung von
						Google Analytics
						näherbringen. Wenn Sie mehr über den Tracking-Dienst erfahren wollen, empfehlen wir diese beiden
						Links: <a
							href="http://www.google.com/analytics/terms/de.html">http://www.google.com/analytics/terms/de.html</a>
						und <a
							href="https://support.google.com/analytics/answer/6004245?hl=de">https://support.google.com/analytics/answer/6004245?hl=de</a>.
					</p>

					<p>Quelle: Erstellt mit dem Datenschutz Generator von <a
							href="https://www.adsimple.at/online-marketing/">AdSimple®
							Online Marketing</a> in Kooperation mit <a
							href="https://www.meinhaushalt.at/">meinhaushalt.at</a></p>
				</div>
			</div>
		</div>

		<Footer></Footer>
	</div>
</template>

<script>

import Footer from '@/components/Footer.vue'
import * as ManualCookies from '@/assets/js/cookie-functions.js'

export default {
	components: {
		Footer
	},
	metaInfo() {
		return {
			title: 'Lumos - Legal',
			meta: [
				{
					name: 'description',
					content: 'Lumos ist die erste studentische Data Science Beratung in Österreich. Gemeinsam mit unserem Team aus talentierten Studierenden unterstützen wir Unternehmen bei der Anwendung neuer Technologien.'
				},
			]
		}
	},
	data() {
		return {
			view: null,
			showMenu: false
		}
	},
	mounted() {
		//Statistic
		ManualCookies.sendCookies("Legal");
	},
	created: function () {
		this.view = this.$route.params.view !== undefined ? this.$route.params.view : 'Impressum'
	}
}
</script>

<style lang="scss">
#legal {

	.nav-link:hover {
		color: $lumos-pink-bright;
		transition: 0.3s ease-in-out;
	}

	.lms-content-frame {
		box-sizing: border-box;
	}
}

.impressum,
.privacy {
	margin: 100px 0;

	.content {
		width: 50%;
		margin-left: auto;
		margin-right: auto;
	}

	h1 {
		font-size: 35px;
		font-weight: bold;
		font-family: "Avenir Next", sans-serif !important;
		margin-top: 100px;
		margin-bottom: 100px;
		text-align: center;
	}

	h2 {
		font-size: 25px;
		font-weight: 800;
		font-family: "Open Sans", sans-serif;
	}

	h3 {
		font-size: 18px;
		font-weight: 800;
		font-family: "Open Sans", sans-serif;
		margin-bottom: 0;
	}

	p {
		font-size: 16px;
		line-height: 32px;
		margin: 20px 0 35px 0;
		margin-top: 0;
		opacity: 0.6;
		font-family: "Open Sans", sans-serif;
	}

	ul.list {
		font-size: 14px;
		line-height: 28px;
		margin: 0 0 35px;
		opacity: 0.6;
		font-family: "Open Sans", sans-serif;
	}
}

/* - - - - - - - - - - - - - */
/* -  Mobile Optimization  - */
/* - - - - - - - - - - - - - */

@media screen and (max-width: 768px) {

	.impressum,
	.privacy {
		margin: 100px 0 100px 0;

		.content {
			width: 80%;
			margin-left: auto;
			margin-right: auto;
		}

		h1 {
			font-size: 30px;
			font-weight: bold;
			font-family: "Avenir Next", sans-serif !important;
			margin-top: 100px;
			margin-bottom: 100px;
			text-align: center;
		}

		h2 {
			font-size: 20px;
			font-weight: 800;
			font-family: "Open Sans", sans-serif;
		}

		h3 {
			font-size: 16px;
			font-weight: 800;
			font-family: "Open Sans", sans-serif;
			margin-bottom: 0;
		}

		p {
			font-size: 13px;
			line-height: 25px;
			margin: 0 0 35px;
			opacity: 0.6;
			font-family: "Open Sans", sans-serif;
		}

		ul.list {
			font-size: 11px;
			line-height: 28px;
			margin: 0 0 35px;
			opacity: 0.6;
			font-family: "Open Sans", sans-serif;
		}
	}
}
</style>